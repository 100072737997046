<template>
  <!--   商家列表 -->
  <a-modal v-model="visible" :centered="true" width="60%" title="商家列表" @cancel="cancel">
    <a-table
      :columns="columns"
      :data-source="data"
      :scroll="{ x: 1300 }"
      :loading="loading"
      :pagination="false"
      rowKey="id"
    >
      <!-- avatar插槽 -->
      <span slot="avatar" slot-scope="text">
        <img :src="text" alt="" />
      </span>
      <!-- 账号状态插槽 -->
      <span slot="accountStatus" slot-scope="text">
        <a-tag :color="text == 1 ? 'green' : text == 2 ? 'red' : ''">
          {{ text == 1 ? '正常' : text == 2 ? '违规' : '' }}
        </a-tag>
      </span>
      <!-- 状态插槽 -->
      <span slot="delFlag" slot-scope="text, record" @click.stop="clickSwitch(record)">
        <span v-if="record.delFlag == 2">
          <a-tag color="red">已注销</a-tag>
        </span>
        <span v-else>
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="record.checked"
            @change="changeSwitch"
          />
        </span>
      </span>
      <!-- 操作插槽 -->
      <span slot="action" slot-scope="text, record" @click.stop="() => {}">
        <a @click.stop="$refs.detailForm.detail(record)">详情</a>
      </span>
    </a-table>
    <!-- 分页组件 -->
    <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
      <a-pagination
        :pageSize="page.pageSize"
        :current="page.pageNo"
        show-size-changer
        :page-size-options="['10', '20', '50', '100']"
        show-quick-jumper
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="pageChange"
        @showSizeChange="sizeChange"
      >
      </a-pagination>
    </div>

    <!-- 引入子组件 -->
    <detail-form ref="detailForm"></detail-form>
    <template slot="footer">
      <a-button key="back" @click="cancel"> 关闭 </a-button>
    </template>
  </a-modal>
</template>

<script>
import { storeAdminDelete, storeAdminPageList } from '@/api/modular/mallLiving/report'
import DetailForm from '../storeAdmin/detailForm.vue'
const columns = [
  {
    title: '序号',
    align: 'left',
    width: '70px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商家昵称',
    align: 'left',
    width: '150px',
    dataIndex: 'nickname',
  },
  {
    title: 'ID号',
    width: '100px',
    align: 'left',
    dataIndex: 'idNo',
  },
  {
    title: '头像',
    width: '100px',
    dataIndex: 'avatar',
    align: 'left',
    scopedSlots: {
      customRender: 'avatar',
    },
  },
  {
    title: '手机号',
    width: '130px',
    dataIndex: 'mobile',
    align: 'left',
  },
  {
    title: '销售额（元）',
    width: '150px',
    dataIndex: 'salesAmount',
    align: 'left',
  },
  {
    title: '粉丝基数',
    width: '130px',
    dataIndex: 'fansNum',
    align: 'left',
  },
  {
    title: '关注基数',
    width: '130px',
    dataIndex: 'followNumBase',
    align: 'left',
  },
  {
    title: '代理商ID',
    width: '130px',
    dataIndex: 'agentIdNo',
    align: 'left',
  },
  {
    title: '代理商手机号',
    width: '130px',
    dataIndex: 'agentMobile',
    align: 'left',
  },
  {
    title: '经营类目',
    width: '130px',
    dataIndex: 'categoryName',
    align: 'left',
  },
  {
    title: '账号状态',
    width: '100px',
    dataIndex: 'accountStatus',
    align: 'left',
    scopedSlots: {
      customRender: 'accountStatus',
    },
  },
  {
    title: '注册时间',
    width: '150px',
    dataIndex: 'registerTime',
    align: 'left',
  },
  {
    title: '个性签名',
    width: '150px',
    dataIndex: 'signature',
    align: 'left',
  },
  {
    title: '店铺关注',
    width: '100px',
    dataIndex: 'followNum',
    align: 'left',
  },
  {
    title: '直播封禁状态',
    width: '156px',
    dataIndex: 'isLiveForbidden',
    customRender: (text) => `${text == 1 ? '封禁' : text == 2 ? '正常' : ''}`,
  },
  {
    title: '状态',
    width: '100px',
    dataIndex: 'delFlag',
    align: 'left',
    scopedSlots: {
      customRender: 'delFlag',
    },
  },
  {
    title: '开店时间',
    width: '150px',
    dataIndex: 'createTime',
    align: 'left',
  },
  {
    title: '注销时间',
    width: '150px',
    dataIndex: 'cancellationTime',
    align: 'left',
  },
  {
    title: '操作',
    align: 'left',
    width: '100px',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action',
    },
  },
]
export default {
  name: 'store-list',
  components: { DetailForm },
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      agentId: '',
      loading: false,
      visible: false,
      recordItem: null,
    }
  },
  methods: {
    cancel() {
      this.visible = false
    },
    init(id) {
      this.agentId = id
      this.visible = true
      this.page.pageNo = 1
      this.getList()
    },

    getList() {
      this.loading = true
      let query = {
        queryParam: { agentId: this.agentId },
        page: this.page,
      }
      storeAdminPageList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await storeAdminDelete(params)
      var text = delFlag == 1 ? '启用' : delFlag == 2 ? '已注销' : '禁用'
      if (data.code == 200) {
        this.$message.success(this.recordItem.nickname + ' 已' + text)
        this.getList()
      }
    },
  },
}
</script>

<style scoped>
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 40px;
  height: 40px;
}
</style>
